import "../styles/app.scss";

import { useEffect } from "react";
import { DefaultSeo } from "next-seo";
import { SWRConfig } from "swr";
import { useRouter } from "next/router";
import Script from "next/script";
import * as ga from "../lib/ga";

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

//

export default function Monokel({ Component, pageProps }) {
  const router = useRouter();

  // Attach client side navigation to links inside rich text wrappers
  useEffect(() => {
    function richTextLinkListener(event) {
      const target = event.target as HTMLAnchorElement;

      if (target.matches?.(".u-richText a")) {
        if (event.defaultPrevented || target.origin !== location.origin) {
          return;
        }

        event.preventDefault();
        router.push(target.href);
      }
    }

    document.addEventListener("click", richTextLinkListener);

    return () => {
      document.removeEventListener("click", richTextLinkListener);
    };
  });

  // Log page views to GA
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <SWRConfig
      value={{
        fallback: pageProps.swrFallback,
        revalidateOnFocus: false,
      }}
    >
      <DefaultSeo
        title="Monokel Eyewear"
        openGraph={{
          type: "website",
          title: "Monokel Eyewear",
          images: [
            {
              url: "https://monokel-eyewear.com/img/monokel-eyewear-1-og.jpeg",
              width: 639,
              height: 335,
              alt: "Monokel Eyewear",
            },
            {
              url: "https://monokel-eyewear.com/img/monokel-eyewear-2-og.jpeg",
              width: 639,
              height: 335,
              alt: "Monokel Eyewear",
            },
          ],
        }}
        additionalLinkTags={[
          {
            rel: "shortcut icon",
            href: "/favicon.svg",
          },
          {
            rel: "shortcut icon",
            href: "/favicon.png",
          },
          {
            rel: "preconnect",
            href: "https://monokel.centra.com",
          },
          {
            rel: "preconnect",
            href: "https://monokel.centracdn.net",
          },
        ]}
        additionalMetaTags={[
          {
            name: "facebook-domain-verification",
            content: "m7w0g25im7qcd8hhmw59asgrrmdzwm7",
          },
        ]}
      />

      <Script>
        {`// Define dataLayer and the gtag function.
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag('consent', 'default', {
              'analytics_storage': 'denied',
              'ad_storage': 'denied',
              'ad_user_data': 'denied', // this parameter is new on Consent Mode v2
              'ad_personalization': 'denied', // this parameter is new on Consent Mode v2
              'wait_for_update': "500"
            });`}
      </Script>

      <Script
        id="ingridTrackingWidget"
        src="https://cdn.ingrid.com/tracking-widget/bootstrap.js"
        strategy="beforeInteractive"
      />

      <Script id="gtm" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KT9XGK9');
      `}
      </Script>

      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=GTM-KT9XGK9"
      />

      <Script id="gtm-init" strategy="afterInteractive">
        {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){window.dataLayer.push(arguments);}
         gtag('js', new Date());
       
         gtag('config', 'GTM-KT9XGK9');
      `}
      </Script>

      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KT9XGK9" height="0" width="0" style="display: none; visibility: hidden;" />`,
        }}
      />

      {/* OneTrust Cookies Consent Notice start for monokel-eyewear.com */}
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="018dd66e-6ae5-7ab7-8187-db71d8e8d643"
        strategy="afterInteractive"
      ></Script>
      <Script type="text/javascript" strategy="afterInteractive">
        {`function OptanonWrapper() {}`}
      </Script>
      {/* OneTrust Cookies Consent Notice end for monokel-eyewear.com */}

      <Component {...pageProps} />
    </SWRConfig>
  );
}
